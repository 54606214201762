import React from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import AssignmentIcon from "@material-ui/icons/Assignment";
import * as SiteConfig from "../../Constants/SiteConfig";

import item1 from "../../assets/img/personal_term/item1.png";
import item2 from "../../assets/img/personal_term/item2.png";
import item3 from "../../assets/img/personal_term/item3.png";
import item4 from "../../assets/img/personal_term/item4.png";
import item5 from "../../assets/img/personal_term/item5.png";
import item6 from "../../assets/img/personal_term/item6.png";
import item7 from "../../assets/img/personal_term/item7.png";
import item8 from "../../assets/img/personal_term/item8.png";
import item9 from "../../assets/img/personal_term/item9.png";
import item10 from "../../assets/img/personal_term/item10.png";
import item11 from "../../assets/img/personal_term/item11.png";
import item12 from "../../assets/img/personal_term/item12.png";
import item13 from "../../assets/img/personal_term/item13.png";

import guideImage from "../../assets/img/personal_term/guide.png";

const labeling = [
	{
		desc: "센터 내 진행되는 서비스 제공, 민원처리, 소관 업무 수행 등",
		title: "처리 목적",
		img: item1,
	},
	{
		desc: "이름, 주소, 연락처, email 등 ※세부항목은 개인정보 처리방침 본문 확인 ",
		title: "처리 항목",
		img: item2,
	},
	{ desc: "정보주체 동의 또는 법률의 규정에 의해서만 제3자 제공", title: "제3자 제공", img: item3 },
	{ desc: "홈페이지 유지보수 업체", title: "처리위탁", img: item4 },
	{ desc: "홈페이지 탈퇴 시", title: "파기", img: item5 },
	{
		desc: "부산예술인복지지원센터 051-745-7239 bscfcenter@bscf.or.kr",
		title: "고충처리부서",
		img: item6,
	},
];

const indexing = [
	{ number: 1, title: "개인정보 처리목적" },
	{ number: 2, title: "개인정보파일 등록 현황" },
	{ number: 3, title: "개인정보의 처리 및 보유기간" },
	{ number: 4, title: "개인정보의 파기 절차 및 방법에 관한 사항" },
	{ number: 5, title: "개인정보의 제3자 제공에 관한 사항" },
	{ number: 6, title: "개인정보 처리업무의 위탁에 관한 사항" },
	{ number: 7, title: "개인정보의 안전성 확보조치에 관한 사항" },
	{ number: 8, title: "정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항" },
	{ number: 9, title: "개인정보 열람청구" },
	{ number: 10, title: "개인정보 보호책임자 및 개인정보보호 업무 담당부서" },
	{ number: 11, title: "권익침해 구제방법" },
	{ number: 12, title: "개인정보 처리방침의 변경" },
];

const useStyles = makeStyles(theme => ({
	paper: {
		marginTop: theme.spacing(4),
		display: "flex",
		flexDirection: "column",
		// alignItems: 'center',
	},
	pageTitle: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: SiteConfig.mainColor,
	},
	submit: {
		margin: theme.spacing(3, 0, 0),
	},
	table: {
		border: "1px solid black",
		borderCollapse: "collapse",
		textAlign: "center",
		padding: 10,
	},
	linkWrapper: {
		fontSize: 16,
	},
	link: { textDecoration: "underline", textDecorationThickness: "1px" },

	labelBox: {
		display: "grid",
		gridTemplateColumns: "repeat(3, 1fr)",
		gap: "20px",
		"@media (max-width: 768px)": {
			gridTemplateColumns: "repeat(2, 1fr)",
		},
	},
	labelItems: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "flex-start",
		"& img": {
			width: "100px",
			height: "100px",
			objectFit: "contain",
			"@media (max-width: 768px)": {
				width: "40px",
				height: "40px",
			},
		},
		"& h3": {
			fontSize: "1.2rem",
			margin: "10px 0",
		},
		"& p": {
			fontSize: "0.8rem",
			textAlign: "center",
			wordBreak: "keep-all",
			wordWrap: "break-word",
		},
	},
	indexingBox: {
		margin: "20px 0",
		padding: "7px",
		display: "grid",
		gridTemplateColumns: "repeat(2, 1fr)",
		border: "1px solid #a3a3a3",
	},
	indexingItems: {
		padding: "7px",
		"& p": {
			fontSize: "0.9rem",
			margin: 0,
		},
	},
	indexingTitle: {
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "center",
	},
	indexingImg: {
		width: "45px",
		height: "45px",
		marginLeft: "5px",
		objectFit: "contain",
	},
	table: {
		border: "1px solid black",
		borderCollapse: "collapse",
		textAlign: "center",
		padding: 10,
		"& th, td": {
			border: "1px solid black",
			padding: 10,
			fontWeight: "normal",
			fontSize: "0.9rem",
			color: "#000ff",
		},
	},
}));

const PersonalTerm = () => {
	const classes = useStyles();

	return (
		<Container component="main" maxWidth="md">
			<CssBaseline />
			<div className={classes.paper}>
				<div className={classes.pageTitle}>
					<Avatar className={classes.avatar}>
						<AssignmentIcon />
					</Avatar>
					<Typography component="h1" variant="h5">
						개인정보처리방침
					</Typography>
					<Typography variant="body2">개인정보 수집 및 이용에 대한 동의</Typography>
				</div>
				<br />
				<br />
				<br />
				(부산예술인복지지원센터 아이컨택 개인정보 처리방침)
				<br />
				부산예술인복지지원센터(이하 ‘센터’)는 정보주체의 자유와 권리 보호를 위해 「개인정보 보호법」
				및 관계 법령이 정한 바를 준수하여, 적법하게 개인정보를 처리하고 안전하게 관리하고 있습니다.
				이에 「개인정보 보호법」 제 30조에 따라 정보주체에게 개인정보의 처리와 보호에 관한 절차 및
				기준을 안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과
				같이 개인정보 처리방침을 수립·공개합니다. 센터는 개인정보 처리방침을 변경하는 경우에는
				시행의 시기, 변경된 내용을 정보주체가 쉽게 확인할 수 있도록 변경 전·후를 비교하여 공개하도록
				할 예정입니다.
				<br />
				<br />
				주요 개인정보 처리 표시(라벨링)
				<br />
				<br />
				<div className={classes.labelBox}>
					{labeling.map((item, index) => {
						return (
							<div className={classes.labelItems}>
								<img src={item.img} alt={item.title} />
								<h3>{item.title}</h3>
								<p>{item.desc}</p>
							</div>
						);
					})}
				</div>
				<br />
				<br />
				목차
				<br />
				<div className={classes.indexingBox}>
					{indexing.map((item, index) => {
						return (
							<div className={classes.indexingItems}>
								<p>
									{item.number}. {item.title}
								</p>
							</div>
						);
					})}
				</div>
				<br />
				<div className={classes.indexingTitle}>
					제1조(개인정보 처리목적)
					<img src={item1} alt="" className={classes.indexingImg} />
				</div>
				<br />
				1. 센터는 개인정보를 사이트 내 진행되는 서비스 제공, 민원 처리, 소관 업무 수행의 목적으로
				수집·보유하고 있습니다
				<br />
				2. 수집한 개인정보는 수집 목적 이외의 용도로는 사용되지 않으며, 이용 목적이 변경되는
				경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할
				예정입니다.
				<br />
				<br />
				<br />
				<div className="indexingTitle">
					제2조(개인정보파일 등록 현황)
					<img src={item1} alt="" className={classes.indexingImg} />
				</div>
				<br />
				1. 센터는 개인정보를 소관 업무수행 및 민원처리를 위해 개인정보 처리방침에서 명시한 범위
				내에서 처리하고 있으며, 「개인정보 보호법」 제32조에 따라 개인정보파일을 운영하고 있습니다.
				<br />
				<span>
					▷개인정보 처리 및 보유기간:{" "}
					<a
						className={classes.link}
						target="_blank"
						href="https://www.privacy.go.kr/front/wcp/dcl/per/personalInfoFileSrhList.do"
					>
						개인정보파일명 보기
					</a>
				</span>
				<br />
				<br />
				<br />
				<div className="indexingTitle">
					제3조(개인정보의 처리 및 보유기간, 처리하는 개인정보의 항목)
					<img src={item12} alt="" className={classes.indexingImg} />
					<img src={item2} alt="" className={classes.indexingImg} />
					<img src={item1} alt="" className={classes.indexingImg} />
				</div>
				<br />
				1. 센터는 개인정보 수집·보유 목적에 필요한 최소한의 정보를 수집·처리하고 있으며, 센터에서
				운영하는 서비스의 이용과정에서 법령의 규정과 정보주체의 동의에 의해서만 개인정보를
				수집·보유하고 있습니다.
				<br />
				2. 센터는 개인정보 보호법 및 관련 법령에서 정하는 보유·이용 기간 또는 정보주체로부터
				개인정보를 수집시 동의받은 개인정보 보유·이용 기간 내에서 개인정보를 처리합니다.
				<br />
				3. 센터는 보유하고 있는 개인정보 처리 및 보유기간, 처리하는 개인정보의 항목의 조회 방법은
				다음과 같습니다.
				<br />
				<span>
					▷개인정보 처리 및 보유기간:{" "}
					<a
						className={classes.link}
						target="_blank"
						href="https://www.privacy.go.kr/front/wcp/dcl/per/personalInfoFileSrhList.do"
					>
						개인정보파일명 보기
					</a>
				</span>
				<br />
				<br />
				<div className="indexingTitle">
					제4조(개인정보의 파기 절차 및 방법에 관한 사항)
					<img src={item5} alt="" className={classes.indexingImg} />
				</div>
				<br />
				1. 센터는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는
				지체없이 해당 개인정보를 파기합니다.
				<br />
				2. 정보주체로부터 동의받은 개인정보의 보유기간이 경과하거나 처리목적이 달성되었음에도
				불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보(또는
				개인정보파일)을 별도의 데이터베이스(DB)로 옮기거나 보관 장소를 달리하여 보존합니다.
				<br />
				3. 개인정보 파기의 절차 및 방법은 다음과 같습니다.
				<br />
				① 파기절차
				<br />
				센터는 파기사유가 발생한 개인정보를 선정하고 파기계획을 수립하여, 개인정보 보호책임자의
				승인을 받아 개인정보를 파기합니다. 개인정보 보호책임자는 개인정보 파기 시행 후 파기 결과를
				확인하고 개인정보파일 파기 관리대장을 작성합니다.
				<br />
				② 파기방법
				<br />
				센터는 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이
				문서에 기록된 개인정보는 분쇄기로 분쇄하거나 소각하여 파쇄합니다.
				<br />
				<br />
				<br />
				<div className="indexingTitle">
					제5조(개인정보 열람청구)
					<img src={item3} alt="" className={classes.indexingImg} />
				</div>
				<br />
				1. 센터는 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에
				해당하는 경우에만 개인정보를 제3자에게 제공합니다.
				<br />
				① 정보주체로부터 별도의 동의를 받은 경우
				<br />
				② 다른 법률에 특별한 규정이 있는 경우
				<br />
				③ 명백히 정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는
				경우
				<br />
				④ 개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게 제공하지 아니하면 다른 법률에서
				정하는 소관업무를 수행할 수 없는 경우로서 보호위원회의 심의·의결을 거친 경우
				<br />
				⑤ 조약, 그 밖의 국제협정의 이행을 위하여 외국정부 또는 국제기구에 제공하기 위하여 필요한
				경우
				<br />
				⑥ 범죄의 수사와 공소의 제기 및 유지를 위하여 필요한 경우
				<br />
				⑦ 법원의 재판업무 수행을 위하여 필요한 경우
				<br />
				⑧ 형(形) 및 감호, 보호처분의 집행을 위하여 필요한 경우
				<br />
				⑨ 공중위생 등 공공의 안전과 안녕을 위하여 긴급히 필요한 경우
				<br />
				<br />
				2. 센터는 개인정보를 제3자에게 제공하는 경우 다음의 항목을 이용자에게 알린 후 동의를
				받겠습니다.
				<br />
				①. 개인정보를 제공받는 자
				<br />
				②. 센터는 개인정보를 제3자에게 제공하는 경우 다음의 항목을 이용자에게 알린 후 동의를
				받겠습니다.
				<br />
				③. 이용 또는 제공하는 개인정보의 항목
				<br />
				④. 개인정보의 보유 및 이용 기간(제공 시에는 제공받는 자의 보유 및 이용 기간을 말한다)
				<br />
				⑤. 동의를 거부할 권리가 있다는 사실 및 동의 거부에 따른 불이익이 있는 경우에는 그 불이익의
				내용
				<br />
				<br />
				<br />
				<div className="indexingTitle">
					제6조(개인정보 처리업무의 위탁에 관한 사항)
					<img src={item4} alt="" className={classes.indexingImg} />
				</div>
				<br />
				1. 센터는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고
				있습니다.
				<br />
				<br />
				<table className={classes.table}>
					<tr>
						<th>위탁받는 자 (수탁자)</th>
						<th>위탁 업무 내용</th>
						<th>위탁 기간</th>
					</tr>
					<tr>
						<td>징검다리 커뮤니케이션(주)</td>
						<td>홈페이지 관리 및 유지보수</td>
						<td>위탁계약 종료시까지</td>
					</tr>
				</table>
				<br />
				2. 센터는 위탁계약 체결 시 「개인정보 보호법」 제26조에 따라 위탁업무 수행목적 외 개인정보
				처리금지, 안전성 확보조치, 재위탁 제한, 수탁자에 대한 관리·감독, 손해배상 등 책임에 관한
				사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고
				있습니다.
				<br />
				3. 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체 없이 본 개인정보 처리방침을 통하여
				공개하도록 하겠습니다.
				<br />
				<br />
				<br />
				<div className="indexingTitle">
					제7조(개인정보의 안정성 확보조치에 관한 사항)
					<img src={item1} alt="" className={classes.indexingImg} />
				</div>
				<br />
				1. 센터는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
				<br />
				① 개인정보 처리시스템에 대한 접근권한 관리 및 정기점검
				<br />
				② 개인정보에 대한 보안프로그램의 설치 및 갱신
				<br />
				③ 개인정보 유출 등 개인정보 침해사고 방지를 위하여 단말기 접근제한 및 보안 프로그램 설치
				<br />
				④ 비인가자에 대한 물리적 장소 출입통제
				<br />
				⑤ 개인정보 보유기한 도래 등 파기사유가 발생한 경우 복구되지 않도록 완전 파기
				<br />
				<br />
				<br />
				<div className="indexingTitle">
					제8조(정보주체와 법정대리인의 권리·의무 및 행사 방법에 관한 사항)
					<img src={item10} alt="" className={classes.indexingImg} />
					<img src={item11} alt="" className={classes.indexingImg} />
				</div>
				<br />
				1. 정보주체(만 14세 미만인 경우에는 법정대리인을 말함)는 센터에 대해 개인정보
				열람ㆍ정정·삭제ㆍ처리정지 요구 등의 권리를 행사할 수 있습니다. 다만, 다른 법령에서 그
				개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
				<br />
				2. 제1항에 따른 권리 행사는 개인정보 처리 방법에 관한 고시 별지 제8호(개인정보 열람
				요구서)를 통해 센터로 서면, 전화, 전자우편, 인터넷 등을 통하여 하실 수 있으며, 센터는 이에
				대해 지체 없이 조치하겠습니다. 다만 열람 신청의 경우 개인정보 보호법 시행령 제43조에 따라
				“열람”은 “정정 또는 삭제”로 봅니다.
				<br />
				3. 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실
				수 있습니다. 이 경우 개인정보 처리 방법에 관한 고시 별지 제11호 서식에 따른 위임장을
				제출하셔야 합니다.
				<br />
				4. 개인정보 열람 및 처리정지 요구는 개인정보 보호법 제35조 제4항, 제37조 제2항에 의하여
				정보주체의 요구를 거절할 수 있습니다.
				<br />
				5. 센터는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등
				요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
				<br />
				<br />
				<img src={guideImage} alt="guide" />
				<br />
				<br />
				<div className="indexingTitle">
					제9조(개인정보 열람청구)
					<img src={item9} alt="" className={classes.indexingImg} />
				</div>
				<br />
				1. 정보주체는 센터에 대한 개인정보 열람 청구를 아래의 부서에 할 수 있으며, 센터는 정보주체의
				개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
				<br />
				▶ 개인정보 열람청구 접수·처리부서
				<br />
				- 접수 부서명 : 부산예술인복지지원센터
				<br />
				- 접수 연락처 : 051-745-7239
				<br />
				<br />
				2. 정보주체는 제1항의 열람청구 접수·처리부서 이외에, 개인정보보호위원회의 ‘개인정보보호
				포털’ 웹사이트(www.privacy.go.kr)를 통하여서도 개인정보 열람청구를 하실 수 있습니다.
				<br />
				▶ 개인정보보호위원회 개인정보보호 포털 ▷ 개인서비스 ▷ 정보주체 권리행사
				<br />
				▷ 개인정보 열람등요구 신청(본인확인을 위하여 아이핀(I-PIN) 등이 있어야 함)
				<br />
				<br />
				<br />
				<div className="indexingTitle">
					제10조(개인정보 보호책임자 및 개인정보보호 업무 담당부서)
					<img src={item13} alt="" className={classes.indexingImg} />
				</div>
				<br />
				1. 센터는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의
				불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
				<br />
				2. 정보주체는 센터의 서비스(또는 사업)를 이용하시면서 발생한 모든 개인정보보호 관련 문의,
				불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의할 수 있습니다.
				센터는 정보주체의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다.
				<br />
				<br />
				<br />
				<table className={classes.table}>
					<tr>
						<th>구분</th>
						<th>부서(직위)⋅성명</th>
						<th>연락처</th>
					</tr>
					<tr>
						<td>개인정보 보호책임자</td>
						<td>기획경영실장 명지정</td>
						<td rowSpan={2}>
							⋅전화 : 051-745-7220, 7223 <br />
							⋅메일 : privacy@bscf.or.kr
						</td>
					</tr>
					<tr>
						<td>개인정보 보호담당자</td>
						<td>기획홍보팀 사원 한지은</td>
					</tr>
					<tr>
						<td>개인정보 취급자</td>
						<td>기획경영실장 명지정</td>
						<td>
							⋅전화 : 051-745-7239 <br />
							⋅메일 : bscfcenter@bscf.or.kr
						</td>
					</tr>
				</table>
				<br />
				<br />
				<div className="indexingTitle">
					제11조(권익침해 구제방법)
					<img src={item8} alt="" className={classes.indexingImg} />
				</div>
				<br />
				1. 정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회,
				한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이
				밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
				<br />
				①. 개인정보 분쟁조정 : (국번없이) 1833-6972 (www.privacy.go.kr)
				<br />
				②. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
				<br />
				③. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)
				<br />
				④. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr/minwon/main)
				<br />
				2. 센터는 정보주체의 개인정보자기결정권을 보장하고, 개인정보침해로 인한 상담 및 피해 구제를
				위해 노력하고 있으며, 신고나 상담이 필요한 경우 아래의 담당부서로 연락해 주시기 바랍니다.
				<br />
				▶ 개인정보보호 관련 고객 상담 및 신고
				<br />
				- 부서명 : 부산예술인복지지원센터
				<br />
				- 연락처 : 051-745-7239, bscfcenter@bscf.or.kr
				<br />
				3. 「개인정보 보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제),
				제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대하여 공공기관의 장이 행한 처분 또는
				부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을
				청구할 수 있습니다.
				<br />
				▶ 중앙행정심판위원회 : (국번없이) 110 (www.simpan.go.kr)
				<br />
				<br />
				<br />
				<div className="indexingTitle">
					제12조(개인정보 처리방침의 변경)
					<img src={item7} alt="" className={classes.indexingImg} />
				</div>
				<br />
				1. 이 개인정보 처리방침은 2024년 10월 24일부터 적용됩니다.
				<br />
				2. 이전의 개인정보처리방침은 아래에서 확인할 수 있습니다.
				<br />
				<a className={classes.link} target="_blank" href="/beforePersonalTerm">
					-~~ 2024. 10. 23. 적용 (클릭)
				</a>
				<br />
				<br />
				<br />
			</div>
		</Container>
	);
};

export default PersonalTerm;
