export const TEST = "/test";
export const ROOT = "/";
export const INDEX = "/index";
export const LOGIN = "/login";
export const GUIDE = "/guide";
export const FAQ = "/faq";
export const ARTISTJOIN = "/artistJoin";
export const CONSULTANTJOIN = "/consultantJoin";
export const CONSULTANTLIST = "/consultantList";
export const CHANGEPASSWORD = "/changePassword";
export const MEMBERSHIP = "/membership";
export const SERVICETERM = "/serviceTerm";
export const PERSONALTERM = "/personalTerm";
export const MYCONSULTING = "/myConsulting";
export const MYINQUIRY = "/myInquiry";
export const SURVEY = "/survey";
export const ADMIN = "/admin";
export const USERINFOEDIT = "/UserInfoEdit";
export const BEFOREPERSONALTERM = "/beforePersonalTerm";
