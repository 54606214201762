import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import AuthenticationContext from "./Context/Authentication";
import FirebaseContext from "./Context/Firebase";
import * as Routes from "./Constants/Routes";
import * as siteConfig from "./Constants/SiteConfig";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Intro from "./Views/Intro/Index";
import Index from "./Views/Index/Index";
import ServiceTerm from "./Views/SiteTerms/ServiceTerm";
import PersonalTerm from "./Views/SiteTerms/PersonalTerm";
import BeforePersonalTerm from "./Views/SiteTerms/BeforePersonalTerm";
import Guide from "./Views/Guide/Index";
import Faq from "./Views/Faq/Index";
import ArtistJoin from "./Views/ArtistJoin/Index";
import ConsultantJoin from "./Views/ConsultantJoin/Index";
import ConsultantList from "./Views/ConsultantList/Index";
import LoginIndex from "./Views/Login/Index";
import Membership from "./Views/Membership/Index";
import ChangePassword from "./Views/Login/ChangePassword";
import MyConsulting from "./Views/MyConsulting/Index";
import MyInquiry from "./Views/MyInquiry/Index";
import Survey from "./Views/Survey/Index";
import Admin from "./Views/Admin/Index";
import Test from "./Views/Test/Test";
import UserEditByAdmin from "./Views/Membership/UserEditByAdmin";

import { ThemeProvider, createMuiTheme } from "@material-ui/core";

firebase.initializeApp(siteConfig.firebaseConfig);
const theme = createMuiTheme({
	palette: {
		primary: {
			main: "#63a527",
		},
	},
});
function App() {
	const [authUser, setAuthUser] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isAddedView, setIsAddedView] = useState(false);

	// const sessionToday = sessionStorage.getItem("today");
	// const todayStr = new Date().toISOString().slice(0, 10);

	useEffect(() => {
		firebase.auth().onAuthStateChanged(currentUser => {
			if (currentUser) {
				firebase
					.firestore()
					.collection("UsersCollection")
					.doc(currentUser.email)
					.get()
					.then(doc => {
						if (doc.exists) {
							setAuthUser(doc.data());
							setIsLoading(false);
						}
					});
			} else {
				setAuthUser(null);
				setIsLoading(false);
			}
		});
		// if (todayStr !== sessionToday) {
		firebase
			.firestore()
			.collection("Global")
			.doc("views")
			.update({
				today: firebase.firestore.FieldValue.increment(1),
				total: firebase.firestore.FieldValue.increment(1),
			})
			.then(() => {
				setIsAddedView(true);
			});
		// }
	}, []);

	// useEffect(() => {
	//   if (isAddedView) {
	//     sessionStorage.setItem("today", todayStr);
	//   }
	// }, [isAddedView]);

	const AdminRoute = ({ component: Component }) => (
		<Route
			render={() =>
				authUser && authUser.userLevel > 9 ? <Component /> : <Redirect to={Routes.INDEX} />
			}
		/>
	);

	const PrivateRoute = ({ component: Component }) => (
		<Route render={() => (authUser !== null ? <Component /> : <Redirect to={Routes.LOGIN} />)} />
	);
	const ClientRoute = ({ component: Component }) => (
		<Route render={() => (authUser === null ? <Component /> : <Redirect to={Routes.INDEX} />)} />
	);

	return isLoading ? (
		<div style={{ padding: "10px" }}>잠시만 기다려주세요.</div>
	) : (
		<FirebaseContext.Provider value={firebase}>
			<AuthenticationContext.Provider value={authUser}>
				<ThemeProvider theme={theme}>
					<BrowserRouter>
						<Header />
						<Switch>
							<Route exact path={Routes.TEST} component={Test} />
							<Route exact path={Routes.ROOT} component={Index} />
							<Route exact path={Routes.INDEX} component={Index} />
							<Route exact path={Routes.SERVICETERM} component={ServiceTerm} />
							<Route exact path={Routes.CONSULTANTLIST} component={ConsultantList} />
							<Route exact path={Routes.PERSONALTERM} component={PersonalTerm} />
							<Route exact path={Routes.BEFOREPERSONALTERM} component={BeforePersonalTerm} />
							<Route exact path={Routes.GUIDE} component={Guide} />
							<Route exact path={Routes.FAQ} component={Faq} />
							<Route exact path={`${Routes.SURVEY}/:id`} component={Survey} />

							<ClientRoute exact path={Routes.ARTISTJOIN} component={ArtistJoin} />
							<ClientRoute exact path={Routes.CONSULTANTJOIN} component={ConsultantJoin} />
							{/* {authUser && authUser.userLevel > 9 ? (
                <AdminRoute
                  exact
                  path={Routes.CONSULTANTLIST}
                  component={ConsultantList}
                />
              ) : (
                <ClientRoute
                  exact
                  path={Routes.CONSULTANTLIST}
                  component={ConsultantList}
                />
              )} */}
							<ClientRoute exact path={Routes.LOGIN} component={LoginIndex} />
							<PrivateRoute exact path={Routes.MEMBERSHIP} component={Membership} />
							<PrivateRoute exact path={Routes.CHANGEPASSWORD} component={ChangePassword} />
							<PrivateRoute exact path={Routes.MYCONSULTING} component={MyConsulting} />
							<PrivateRoute exact path={Routes.MYINQUIRY} component={MyInquiry} />
							<AdminRoute exact path={Routes.ADMIN} component={Admin} />
							<AdminRoute exact path={Routes.USERINFOEDIT} component={UserEditByAdmin} />

							<Redirect to="/" />
							<Redirect from="/" to="/" />
						</Switch>
						<Footer whiteFont />
					</BrowserRouter>
				</ThemeProvider>
			</AuthenticationContext.Provider>
		</FirebaseContext.Provider>
	);
}

export default App;
